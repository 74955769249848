import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Parser from "../components/parser.js";
import Time from "../components/time.js";
import { Link } from "gatsby";

const performSearch = async (query, endpoint) => {
  const graphqlQuery = `query Search($search: String = "${query}") {
    pages(where: {search: $search}) {
      nodes {
        title
        uri
        seo {
          description
        }
      }
    }
    posts(where: {search: $search}) {
      nodes {
        date
        title
        uri
        seo {
          description
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }`;
  const payload = {
    operationName: "Search",
    query: graphqlQuery,
    variables: null
  };
  let results = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(payload)
  });
  results = await results.json();
  return results.data;
};

const SearchPage = (props) => {
  const [results, updateResults] = useState({});
  const [query, setQuery] = useState("");
  useEffect(async () => {
    updateResults({});
    const query = props?.location?.state?.query;
    if (!query) {
      return;
    }
    setQuery(query);
    const endpoint = props.data.sitePlugin.pluginOptions.url;
    const results = await performSearch(query, endpoint);
    updateResults(results);
  }, [props?.location?.state?.query]);
  const searchResults = () => (<section className="wp-block-group search">
    {
      query.length
        ? (<>
          {
            (Object.keys(results).length === 0) ? (<div className="search-loader">
              <p>Chargement en cours...</p>
              <span></span>
            </div>)
              : <h2>{ (results?.posts?.nodes?.length+results?.pages?.nodes?.length) } résultat{(results?.posts?.nodes?.length+results?.pages?.nodes?.length > 1) ? "s":""} de recherche pour «&nbsp;{query}&nbsp;»</h2> 
          }
          {
            results?.pages?.nodes?.length ? (<>
              <ul className="is-style-list-no-disc">
                {
                  results.pages.nodes.map(page => {
                    return (<li>
                      <Link to={ page.uri }>
                        <Parser content={ `<p>Page</p><h3>${ page.title }</h3><p>${ page.seo.description }</p>` } />
                      </Link>
                    </li>);
                  })
                }
              </ul>
            </>) : (<></>)
          }
          {
            results?.posts?.nodes?.length ? (<>
              <ul className="is-style-list-no-disc">
                {
                  results.posts.nodes.map(page => {
                    return (<li>
                      <Link to={ page.uri }>
                        <p>Actualités</p>
                        <Parser content={ `<h3>${ page.title }</h3>` } />
                        <div className="wp-block-columns">
                          {
                            page.categories.nodes.map((cat,i) => {
                              return <p key={i}><Parser content={ cat.name } /></p>;
                            })
                          }
                          <Time date={ page.date } />
                        </div>
                        <Parser content={ `<p>${ page.seo.description }</p>` } />
                      </Link>
                    </li>);
                  })
                }
              </ul>
            </>) : (<></>)
          }
          {
            (results?.posts?.nodes?.length === 0 && results?.pages?.nodes?.length === 0)
              ? <p>Aucun résultat n&apos;a été obtenu pour votre recherche. Vous pouvez réessayer en modifiant les termes recherchés</p>
              : <></>
          }
        </>)
        : (<p>Utilisez le champ ci-dessus pour effectuer une recherche sur le site.</p>)
    }
  </section>);
  return (
    <Layout infos={{
      postType: "search",
      title: query.length ? "Résultats de recherche : "+query : "Moteur de recherche"
    }}
    seo={{
      description: props.data.wpTemplate?.seo.description,
    }}>
      <Parser
        content={ props.data.wpTemplate?.content || "" }
        title={ props.data.wpTemplate?.title + (query.length ? "&nbsp;: «&nbsp;" + query + "&nbsp;»" : "") }
        child={ searchResults }
      />
    </Layout>
  );
};
export const pageQuery = graphql`
  query SearchPage {
    site {
      siteMetadata {
        title
      }
    }
    wpTemplate(template_reference: {referenceTemplate: {eq: "search"}}) {
      content
      title
      slug
      seo {
        title
        description
      }
    }
    sitePlugin(name: {eq: "gatsby-source-wordpress"}) {
      pluginOptions
    }
  }
`;
export default SearchPage;
